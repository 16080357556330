import { IPushGroupCreate, IPushGroupWithLinks } from "@novel/shared/interfaces/PushGroup";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IPushGroupWithStats extends IPushGroupCreate {
    delivered?: number;
    scheduled?: number;
}
export interface ILinkReducerState {
    isLoading: boolean;
    data: IPushGroupWithLinks[];
}

const initialState: ILinkReducerState = {
    isLoading: true,
    data: [],
};

export const linkReducer: NovelVendorSubReducer<ILinkReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_LINKS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_LINKS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_LINKS": {
            return {
                ...state,
                data: action.payload.links,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
