import { IPushGroupWithLinks } from "@novel/shared/interfaces/PushGroup";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IPushGroupWithStats extends IPushGroupWithLinks {
    delivered?: number;
    scheduled?: number;
}
export interface IPassPushNotificationReducerState {
    isLoading: boolean;
    data: IPushGroupWithStats[];
}

const initialState: IPassPushNotificationReducerState = {
    isLoading: true,
    data: [],
};

export const pushNotificationReducer: NovelVendorSubReducer<IPassPushNotificationReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_PUSH_NOTIFICATIONS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_PUSH_NOTIFICATIONS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_PUSH_NOTIFICATIONS": {
            return {
                ...state,
                data: action.payload.pushNotifications,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
