import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface ILinksAnalyticsReducerState {
    isLoading: boolean;
    data: {
        referred?: number;
        active?: number;
        totalClicks?: number;
    };
}

const initialState: ILinksAnalyticsReducerState = {
    isLoading: true,
    data: {},
};

export const linksAnalyticsReducer: NovelVendorSubReducer<ILinksAnalyticsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_LINKS_ANALYTICS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_LINKS_ANALYTICS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_LINKS_ANALYTICS": {
            return {
                ...state,
                data: action.payload.linkAnalytics,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
