import React from "react";
import type { NextComponentType, NextPageContext } from "next";
import type { AppProps as DefaultAppProps } from "next/app";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/nextjs";
import { GlobalErrorBoundary } from "@novel/shared/components/GlobalErrorBoundary";
import { AuthContainer } from "@vendor-portal/components/AuthContainer";
import { StyledThemeProvider } from "@vendor-portal/definitions/styled-components";
import { vendorStore } from "@vendor-portal/redux/store";
import "../styles/global.scss";
import Head from "next/head";
import Script from "next/script";
import Favicon from "@vendor-portal-public/favicon.ico";
import { AppEnv, AppEnvEnum } from "@novel/shared/utils/env";
interface AppProps extends DefaultAppProps {
  readonly Component: NextComponentType<NextPageContext, never, {
    props: any;
  }> & {
    isSuperUserRoute?: boolean;
    isPublicRoute?: boolean;
    redirectIfAuthed?: boolean;
  };
}
export default function App({
  Component,
  pageProps,
  router
}: AppProps): JSX.Element {
  return <StyledThemeProvider>
            <Head>
                <Script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js" />

                <title>Novel | Wallet Passes for Your Brand</title>

                <link rel="shortcut icon" type="image/png" href={Favicon.src} />
                <link rel="canonical" href="https://www.novel.com" />

                <meta name="twitter:title" content="Novel | Wallet Passes for Your Brand" />
                <meta name="twitter:description" content="We make it simple for brands to create and launch premium experiences for their communities, with a robust toolset to create exclusive experiences for products, discounts, and content." />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image" content="https://novel-commerce.s3.us-east-1.amazonaws.com/8428704142303993-novel-open-graph.png" />

                <meta name="og:title" content="Novel | Wallet Passes for Your Brand" />
                <meta name="og:description" content="We make it simple for brands to create and launch premium experiences for their communities, with a robust toolset to create exclusive experiences for products, discounts, and content." />
                <meta name="og:type" content="website" />
                <meta name="og:image" content="https://novel-commerce.s3.us-east-1.amazonaws.com/8428704142303993-novel-open-graph.png" />
            </Head>
            <Provider store={vendorStore}>
                <GlobalErrorBoundary sentryContext={Sentry.getCurrentHub()}>
                    <AuthContainer isSuperUserRoute={Component.isSuperUserRoute} isPublicRoute={Component.isPublicRoute} redirectIfAuthed={Component.redirectIfAuthed}>
                        <Component props={{
            ...pageProps,
            ...router.query
          }} />
                    </AuthContainer>
                </GlobalErrorBoundary>
            </Provider>
            {AppEnv === AppEnvEnum.production && <Script type="text/javascript">
                    {`(function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "k2a4w2cxm5");`}
                </Script>}
        </StyledThemeProvider>;
}