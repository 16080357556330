import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IPassPushNotificationAnalyticsReducerState {
    isLoading: boolean;
    data: {
        delivered?: number;
        scheduled?: number;
    };
}

const initialState: IPassPushNotificationAnalyticsReducerState = {
    isLoading: true,
    data: {},
};

export const pushNotificationAnalyticsReducer: NovelVendorSubReducer<
    IPassPushNotificationAnalyticsReducerState
> = (state = initialState, action) => {
    switch (action.type) {
        case "LOADING_PUSH_NOTIFICATION_ANALYTICS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_PUSH_NOTIFICATION_ANALYTICS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_PUSH_NOTIFICATION_ANALYTICS": {
            return {
                ...state,
                data: action.payload.pushNotificationAnalytics,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
