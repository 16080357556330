import { asyncDispatch } from "@vendor-portal/redux/utils/asyncDispatch";
import { NovelThunkVendorAction } from "@vendor-portal/redux/vendorTypedRedux";
import { typedFrontendVendorApi } from "@vendor-portal/utils/typedFrontendVendorApi";

export const loadProducts =
    (): NovelThunkVendorAction<"LOADING_PRODUCTS" | "ERROR_LOADING_PRODUCTS" | "LOADED_PRODUCTS"> =>
    (dispatch) =>
        asyncDispatch(
            typedFrontendVendorApi.getReq("/api/vendor/product"),
            () =>
                dispatch({
                    type: "LOADING_PRODUCTS",
                    payload: undefined,
                }),
            (failure) =>
                dispatch({
                    type: "ERROR_LOADING_PRODUCTS",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                }),
            (productsRes) =>
                dispatch({
                    type: "LOADED_PRODUCTS",
                    payload: {
                        productsRes,
                    },
                }),
        );
