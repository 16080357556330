export * from "./common";
export * from "./dark";
export * from "./light";
import { AppProvider } from "@shopify/polaris";
import { LinkLikeComponentProps } from "@shopify/polaris/build/ts/latest/src/utilities/link/types";
import en from "@shopify/polaris/locales/en.json";
import merge from "lodash/merge";
import Link from "next/link";
import React, { useCallback, useContext } from "react";
import { ThemeProvider } from "styled-components";
import { common } from "./common";
import { dark } from "./dark";
import { light } from "./light";
export const ThemeContext = React.createContext({
  theme: "light",
  toggle: () => {
    void 0;
  }
});
export const useTheme = () => {
  const {
    theme,
    toggle
  } = useContext(ThemeContext);
  return {
    theme: merge({}, common, theme === "light" ? light : dark),
    toggle,
    themeName: theme
  };
};
export const StyledThemeProvider: React.FC = ({
  children
}) => {
  const [theme, setTheme] = React.useState<"light" | "dark">("light");
  const toggle = useCallback(() => {
    setTheme(theme => theme === "light" ? "dark" : "light");
  }, []);
  const values = React.useMemo(() => ({
    theme,
    toggle
  }), [toggle, theme]);
  const currentThemeObj = theme === "light" ? light : dark;
  return <ThemeContext.Provider value={values}>
            <AppProvider i18n={en} linkComponent={LinkWrapper}>
                <ThemeProvider theme={currentThemeObj}>{children}</ThemeProvider>
            </AppProvider>
        </ThemeContext.Provider>;
};

// taken from: https://stackoverflow.com/questions/68836582/connect-shopify-polaris-links-with-nextjs-links
function LinkWrapper(props: LinkLikeComponentProps) {
  const {
    children,
    url,
    ...rest
  } = props;
  return <Link legacyBehavior href={url} shallow>
            <a {...rest}>{children}</a>
        </Link>;
}