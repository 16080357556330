import { IPaginatedPassHolderWithDevices } from "@novel/shared/interfaces/OrgCustomerRelationship";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IRewardsMembersReducerState extends IPaginatedPassHolderWithDevices {
    isLoading: boolean;
}

const initialState: IRewardsMembersReducerState = {
    isLoading: true,
    totalCount: 0,
    pageSize: 0,
    pageNumber: 0,
    totalPages: 0,
    data: [],
};

export const rewardsMembersReducer: NovelVendorSubReducer<IRewardsMembersReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_REWARDS_MEMBERS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_REWARDS_MEMBERS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_REWARDS_MEMBERS": {
            return {
                ...state,
                totalCount: action.payload.paginatedRewardsMembers.totalCount,
                pageSize: action.payload.paginatedRewardsMembers.pageSize,
                pageNumber: action.payload.paginatedRewardsMembers.pageNumber,
                totalPages: action.payload.paginatedRewardsMembers.totalPages,
                data: action.payload.paginatedRewardsMembers.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
