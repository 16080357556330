import * as React from "react";
function MemberPassIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22 22" xmlSpace="preserve" {...props}>
            <path d="M12.1,4.6c2.1,0,4.3,0,6.4,0c1.4,0,2.7,1.1,2.9,2.5c0,0.2,0,0.4,0,0.6c0,2.9,0,5.9,0,8.8c0,1.2-0.5,2-1.5,2.6
                c-0.4,0.3-0.9,0.4-1.4,0.4c-4.3,0-8.5,0-12.8,0c-1.4,0-2.7-1-2.9-2.5c0-0.2,0-0.4,0-0.5c0-2.9,0-5.9,0-8.8c0-1.2,0.6-2.1,1.6-2.7
                c0.4-0.2,0.9-0.3,1.3-0.3C7.8,4.6,9.9,4.6,12.1,4.6z M12.1,5.6c-2.1,0-4.2,0-6.4,0c-0.6,0-1.1,0.2-1.5,0.7C3.9,6.6,3.7,7.1,3.7,7.6
                c0,2.1,0,4.1,0,6.2c0,0.9,0,1.8,0,2.7c0,1.1,0.9,1.9,2,1.9c4.2,0,8.5,0,12.7,0c0.3,0,0.6-0.1,0.9-0.2c0.7-0.4,1.1-1,1.1-1.8
                c0-2.9,0-5.8,0-8.7c0-0.1,0-0.3,0-0.4c-0.2-1-1-1.6-2-1.6C16.3,5.6,14.2,5.6,12.1,5.6z" fill={props.stroke || "white"} stroke="none" />
            <path d="M18.8,12.3h-0.1c-1.4,0-2.8,0-4.1,0c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0.1-0.2,0.3-0.4,0.4
                c-0.3,0.3-0.7,0.4-1.2,0.3c-0.3-0.1-0.6-0.3-0.8-0.5c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.3-0.4-0.4-0.7-0.4c-0.2,0-0.4,0-0.7,0
                s-0.5,0-0.8,0c-0.7,0-1.3,0-2,0c-0.5,0-1,0-1.5,0c-0.1,0-0.1,0-0.1-0.1c-0.2-0.3,0-0.8,0.2-1C5.6,11.1,5.9,11,6.2,11
                c3.9,0,7.7,0,11.6,0c0.6,0,1,0.4,1,1C18.8,12.1,18.8,12.2,18.8,12.3z" fill={props.stroke || "white"} stroke="none" />
            <path d="M18.8,10.4c-4.6,0-9.1,0-13.7,0C5,9.9,5.3,9.4,5.7,9.1C5.8,9,6,9,6.2,9c3.2,0,6.5,0,9.7,0c0.6,0,1.3,0,1.9,0
                c0.5,0,1,0.5,1,1C18.8,10.1,18.8,10.3,18.8,10.4z" fill={props.stroke || "white"} stroke="none" />
            <path d="M18.8,8.3c-4.6,0-9.1,0-13.7,0c0-0.6,0.4-1.1,0.9-1.2c0.1,0,0.1,0,0.2,0c3.9,0,7.8,0,11.6,0c0.6,0,1,0.4,1,1
                C18.8,8.1,18.8,8.2,18.8,8.3z" fill={props.stroke || "white"} stroke="none" />
        </svg>;
}
export default MemberPassIcon;